//# babelOptions minify=yes comments=no mangle=no debuginfo=yes

// erfordert (inline)/s/SCRIPTS/domhelpers.js

D.loaded.then(function() 
{
    document.querySelectorAll(".dynamic-iframe").forEach(function(n){
    	var ifr=n.querySelector("iframe");
    	var loaded=false;
    	var loadfun;
    	ifr.on("load",loadfun=function(ev)
    	{
    	    if (loaded)
    	        return;
    	    loaded=true;
    	    var win=this.contentWindow;
    	    var doc=win.document;

    	    var t=doc.querySelector("#cms_template");
    	    doc.querySelector("head").insertAdjacentHTML('beforeend','<base target="_parent" href="../-/">')
    	    var oldh=-1;
    	    function fres()
    	    {
    	        try
    	        {
    	            //ifr=document.querySelector("#dynamic-iframe-374 iframe");
        	        t=ifr && ifr.contentDocument.querySelector("#cms_template");
        	        if (t)
        	        var h=t ? t.scrollHeight : 0;
        	        if (t && h!=oldh)
        	        {
            	        ifr.style.height=h+'px';
            	        oldh=h;
        	        }
            	    if (window.requestAnimationFrame)
                        window.requestAnimationFrame(fres);
    	        }
    	        catch(ex)
    	        {
    	            debugger;
    	        }
    	    }
    	    if (window.requestAnimationFrame)
                window.requestAnimationFrame(fres);
            else
                window.setInterval(fres,20);
    	})
    	//if (ifr.contentWindow.document.readyState === 'complete' && !loaded)//aus irgendeinem Grund true, obwohl readyState = 'loading' ... Computer spinnen!
    	if (ifr.contentWindow.document.getElementById('cms_template'))
    	    loadfun.call(ifr);
	})
    if (parent.cms && parent.Y.cms && parent.Y.cms.extui)
    {
        parent.Y.cms.extui.dyniframebutton=function (n,ifr)
        {
        	document.querySelectorAll(".dynamic-iframe").forEach(function(n){
            	var ifr=n.querySelector("iframe");
                var src=ifr.getAttribute("src",2).replace(/.*\//,'');
        		n.insertAdjacentHTML('beforeend','<button type=button class="extui">Importseite '+src+' aufrufen</button>');
        		var knopf=n.lastElementChild;
        		knopf.addEventListener("click",function (ev)
        		{
        	 		parent.cms.cwin.location=src;
        		});
        	});
        }.bind()
    }
});